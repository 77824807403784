import React, { FC, useState, useEffect, useRef, useMemo, useContext } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';

import useClickOutside from 'hooks/useClickOutside';

import { CountryContext } from '../../context/CountryContext/CountryContext';
import { ICountryContext } from '../../context/CountryContext/model';

import { IFilterComponent } from './model';
import './FilterComponent.scss';
import './FilterComponentRTL.scss';

const ESCAPE_KEY = 'Escape';
const ESCAPE_KEY_CODE = 27;

const FilterComponent: FC<IFilterComponent> = ({
  filterDefaultText,
  currentCategory,
  filterCategory,
  filterCategoryCountry,
}) => {
  const wrapperFilterRef = useRef(null);

  const [isFiltersVisible, setIsFiltersVisible] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const { currentCountry, allUmbracoProducts, allUmbracoProductListing } = useContext<
    ICountryContext
  >(CountryContext);
  const extraCategory: any = allUmbracoProducts?.nodes.find((i) => i.properties.showCountry);
  const currentLinks: any = [];
  allUmbracoProductListing.nodes.forEach((product) => {
    const arr = product.url.split('/');
    arr.forEach((i) => {
      if (i === currentCountry) {
        currentLinks.push(product);
      }
    });
  });

  let productsAllCategory: any = [];

  const handlerVisibleFilter = useMemo(
    () => () => {
      setIsFiltersVisible(!isFiltersVisible);
    },
    [isFiltersVisible]
  );

  const handlerKeyEscapeFilters = useMemo(
    () => (e: KeyboardEvent) => {
      const isEscapeKeyPressed = e.key === ESCAPE_KEY || e.keyCode === ESCAPE_KEY_CODE;

      if (isEscapeKeyPressed && isFiltersVisible) {
        setIsFiltersVisible(!isFiltersVisible);
      }
    },
    [isFiltersVisible]
  );

  const handlerByScrollEscapeFilters = useMemo(
    () => () => {
      setIsFiltersVisible(false);
    },
    []
  );

  const handlerOnBlurEscapeFilters = useMemo(
    () => (filterState: boolean) => () => {
      setIsFiltersVisible(filterState);
    },
    []
  );

  useEffect(() => {
    window.addEventListener('keydown', handlerKeyEscapeFilters);
    window.addEventListener('scroll', handlerByScrollEscapeFilters);

    return () => {
      window.removeEventListener('keydown', handlerKeyEscapeFilters);
      window.removeEventListener('scroll', handlerByScrollEscapeFilters);
    };
  }, []);
  const isBrowser = typeof window !== 'undefined';

  useClickOutside(wrapperFilterRef, handlerOnBlurEscapeFilters(false));

  if (
    loading &&
    extraCategory?.properties?.showCountry[0]?.properties.country[0] === currentCountry
  ) {
    productsAllCategory.push(extraCategory);
    setLoading(false);
  } else {
    productsAllCategory = currentLinks.splice(0, 5);
  }

  return (
    <div className="filter-component" ref={wrapperFilterRef}>
      <button
        className={classNames('filter-component__block', {
          'filter-component__block--extended': currentCategory[0].length > 35,
        })}
        type="button"
        onClick={handlerVisibleFilter}
      >
        <span className="filter-component__fixed-text">{filterDefaultText}</span>
        <div className="filter-component__show-result">{currentCategory[0]}</div>
        <span
          className={classNames(`filter-component__arrow`, {
            'icon-arrow-down--filter-up': isFiltersVisible,
            'icon-arrow-down--filter-down': !isFiltersVisible,
          })}
        />
      </button>
      {isBrowser && window.location.pathname.split('/')[2] === '' ? (
        <div
          className={classNames(`filters-categories`, {
            [`filters-categories--vissible`]: isFiltersVisible,
          })}
        >
          {filterCategoryCountry?.map(({ properties }) => (
            <Link
              key={properties.title}
              to={properties.cta[0]?.url}
              className="filters-categories__link"
            >
              {properties.title}
            </Link>
          ))}
        </div>
      ) : (
        <div
          className={classNames(`filters-categories`, {
            [`filters-categories--vissible`]: isFiltersVisible,
          })}
        >
          {filterCategory?.map(({ properties }) => (
            <Link
              key={properties.title}
              to={properties.cta[0]?.url}
              className="filters-categories__link"
            >
              {properties.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  );
};

export default FilterComponent;
